<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <!-- <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button> -->
          <button type="button" @click="showModal({type:'cancel'})" class="btn btn-inverse" title="Kembali">Kembali</button>
        </span>
      </h3>
      <hr>

      <div class="row">
          <div class="col-md-12 justify-content-md-center">
              <div class="col-md-6 pull-left">
                  <small class="text-muted">Nama Berkas</small>
                  <h6>{{ state.detail.namafolder === "" ? '-' : state.detail.namafolder }}</h6>
                  <small class="text-muted">Klasifikasi</small>
                  <h6>{{ state.detail.klasifikasi === "" ? '-' : state.detail.klasifikasi }}</h6>
                  <small class="text-muted">Masa Retensi</small>
                  <h6>{{ state.detail.inforetensi === "" ? '-' : state.detail.inforetensi }}</h6>
                  <small class="text-muted">Akhir Retensi</small>
                  <h6>{{ state.detail.akhir_retensi === "" ? '-' : state.detail.akhir_retensi }}</h6>
              </div>
              <div class="col-md-6 pull-left">
                <small class="text-muted">Info Berkas</small>
                <h6>{{ state.detail.information === "" ? '-' : state.detail.information }}</h6>
                <small class="text-muted">Aksi</small>
                <h6>
                  <!-- <button type="button" @click="goRetensi()" class="btn btn-success" title="Perpanjang Masa Retensi">Perpanjang Masa Retensi</button> -->
                  <button type="button" @click="showModal({type:'retensi'})" class="btn btn-success" title="Perpanjang Masa Retensi">Perpanjang Masa Retensi</button>
                </h6>
                <h6>
                  <!-- <button type="button" @click="goMusnah()" class="btn btn-danger" title="Masukkan Eligible Musnah">Masukkan Eligible Musnah</button> -->
                  <button type="button" @click="showModal({type:'musnah'})" class="btn btn-danger" title="Masukkan Eligible Musnah">Masukkan Eligible Musnah</button>
                </h6>
                <h6>
                  <!-- <button type="button" @click="goPermanen()" class="btn btn-warning" title="Masukkan Eligible Permanen">Masukkan Eligible Permanen</button> -->
                  <button type="button" @click="showModal({type:'permanen'})" class="btn btn-warning" title="Masukkan Eligible Permanen">Masukkan Eligible Permanen</button>
                </h6>
              </div>
          </div>
      </div>
      <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" :action="ActionChangeable" :confirmation="isConfirmation" @close="onClose"/>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs
  },
  data() {
    return {
      isTouched: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: '',
      modalContent: '',
      isConfirmation: false,
      ActionChangeable: null,
    };
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.folderdinilai;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("folderdinilai/STATE", state);
      this.$store.dispatch("folderdinilai/getFolderdinilaiById", this.$route.params);
    },
    onClose(){
      this.isVisible = false;
    },
    showModal(option){
      this.isVisible = true;
      if (option.type == 'cancel') {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack; 
      } else if (option.type == 'retensi') {
        this.modalTitle = "Perpanjang Retensi Arsip?";
        this.modalContent = "<p>Apakah anda yakin Perpanjang Retensi Arsip?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goRetensi;
      } else if (option.type == 'musnah') {
        this.modalTitle = "Masukkan Daftar Eligible Musnah?";
        this.modalContent = "<p>Apakah anda yakin Masukkan Daftar Eligible Musnah?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goMusnah;
      } else if (option.type == 'permanen') {
        this.modalTitle = "Masukkan Daftar Eligible Permanen?";
        this.modalContent = "<p>Apakah anda yakin Masukkan Daftar Eligible Permanen?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goPermanen;
      }
    },
    goBack() {
      this.$store.dispatch("folderdinilai/onCancel");
    },
    goRetensi() {
      this.$store.dispatch("folderdinilai/onRetensi");
    },
    goPermanen() {
      const data = {
        id: this.$route.params.id,
        flag_folder: 6,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("folderdinilai/submitPermanen", data);
    },
    goMusnah() {
      const data = {
        id: this.$route.params.id,
        flag_folder: 3,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("folderdinilai/submitMusnah", data);
    },
  }
};
</script>
